<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine the change in standard entropy,
        <stemble-latex content="$\Delta \text{S}^\circ ,$" />
        for the following reaction at
        <stemble-latex content="$25^\circ\text{C}$" />
        using the table of
        <stemble-latex content="$\text{S}^\circ$" />
        values given below (units of
        <stemble-latex content="$\text{J K}^{-1}\text{mol}^{-1}$" />
        ).
      </p>

      <p class="mb-6 pl-14">
        <stemble-latex :content="`$\\ce{${mol1}(g) + ${mol2} -> ${mol3} + ${mol4}}$`" />
      </p>

      <v-simple-table class="mb-5" style="width: 500px">
        <tbody>
          <tr style="height: 15px">
            <td class="pa-1">
              <stemble-latex :content="`$\\text{S}^{\\circ}(\\ce{${mol1}(g)) =}$`" />
            </td>
            <td style="text-align: right" class="pr-20">
              <latex-number :number="mol1Entropy" />
            </td>
            <td class="pl-5">
              <stemble-latex :content="`$\\text{S}^{\\circ}(\\ce{${mol1}(l)) =}$`" />
            </td>
            <td style="text-align: right" class="pa-1">
              <latex-number :number="mol1EntropyLiquid" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td class="pa-1">
              <stemble-latex content="$\text{S}^{\circ}(\ce{O(g)) =}$" />
            </td>
            <td style="text-align: right" class="pr-20">
              <latex-number number="161.1" />
            </td>
            <td class="pl-5">
              <stemble-latex content="$\text{S}^{\circ}(\ce{O2(g)) =}$" />
            </td>
            <td style="text-align: right" class="pa-1">
              <latex-number number="205.2" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td class="pa-1">
              <stemble-latex content="$\text{S}^{\circ}(\ce{CO(g)) =}$" />
            </td>
            <td style="text-align: right" class="pr-20">
              <latex-number number="197.7" />
            </td>
            <td class="pl-5">
              <stemble-latex content="$\text{S}^{\circ}(\ce{CO2(g)) =}$" />
            </td>
            <td style="text-align: right" class="pa-1">
              <latex-number number="213.8" />
            </td>
          </tr>

          <tr style="height: 15px">
            <td class="pa-1">
              <stemble-latex content="$\text{S}^{\circ}(\ce{H2O(l)) =}$" />
            </td>
            <td style="text-align: right" class="pr-20">
              <latex-number number="70.0" />
            </td>
            <td class="pl-5">
              <stemble-latex content="$\text{S}^{\circ}(\ce{H2O(g)) =}$" />
            </td>
            <td style="text-align: right" class="pa-1">
              <latex-number number="188.8" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <calculation-input
        v-model="inputs.Srxn"
        class="mb-4"
        prepend-text="$\Delta\text{S}^{\circ}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';

export default {
  name: 'Question278',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Srxn: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          return {
            mol1Var: 'C2H5OH',
            mol2Var: '3 O2(g)',
            mol3Var: '2 CO2(g)',
            mol4Var: '3 H2O(g)',
            mol1EntropyVar: '282.59',
            mol1EntropyLiquidVar: '161.04',
          };
        case 2:
          return {
            mol1Var: 'C4H9OH',
            mol2Var: '6 O2(g)',
            mol3Var: '4 CO2(g)',
            mol4Var: '5 H2O(g)',
            mol1EntropyVar: '362.75',
            mol1EntropyLiquidVar: '226.35',
          };
        case 3:
          return {
            mol1Var: 'C3H6O',
            mol2Var: '4 O2(g)',
            mol3Var: '3 CO2(g)',
            mol4Var: '3 H2O(g)',
            mol1EntropyVar: '295.35',
            mol1EntropyLiquidVar: '200.41',
          };
        case 4:
          return {
            mol1Var: 'CH3COOH',
            mol2Var: '2 O2(g)',
            mol3Var: '2 CO2(g)',
            mol4Var: '2 H2O(g)',
            mol1EntropyVar: '282.50',
            mol1EntropyLiquidVar: '159.83',
          };
        default:
          return {
            mol1Var: 'ERROR',
            mol2Var: 'O2(g)',
            mol3Var: 'CO2(g)',
            mol4Var: 'H2O(g)',
            mol1EntropyVar: '',
            mol1EntropyLiquidVar: '',
          };
      }
    },
    mol1() {
      return this.versionData.mol1Var;
    },
    mol2() {
      return this.versionData.mol2Var;
    },
    mol3() {
      return this.versionData.mol3Var;
    },
    mol4() {
      return this.versionData.mol4Var;
    },
    mol1Entropy() {
      return this.versionData.mol1EntropyVar;
    },
    mol1EntropyLiquid() {
      return this.versionData.mol1EntropyLiquidVar;
    },
  },
};
</script>
